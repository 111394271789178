<template>
<!-- 在外部自设显示列表  定义一个单独变化的列表  循环遍历变化的列表 -->
  <div style="display:inline">
      <el-tooltip content="自定义内容显示" placement="top">
        <span type="primary" class="el-icon-setting" @click="dialogVisible=true"></span>
      </el-tooltip>
      <el-dialog
        title="内容显示"
        :visible.sync="dialogVisible"
        width="48%"
        style="z-index:99999"
        >
          <el-checkbox  v-model="isAll" @change="handleCheckAllChange">全选</el-checkbox>
          <div style="margin: 15px 0;"></div>
          <el-checkbox-group v-model="checked">
            <el-row :gutter="15">
              <el-col v-for="item in tableList" :key="item.prop" :span="4">
                <el-checkbox @change="length" style="marginBottom:10px"  :label="item" >{{item.name}}</el-checkbox>
              </el-col>
            </el-row>
          </el-checkbox-group>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="sure">确 定</el-button>
        </span>
      </el-dialog>
  </div>
</template>

<script>
export default {
  props:{
    tableList:{
      type:Array,
    }
  },
  data(){
    return{
      isAll:'',
      checked:[],
      dialogVisible:false
    }
  },
  methods:{
    handleCheckAllChange(){
      if(this.isAll){
            this.checked=this.tableList
      }else {
            this.checked=[]
      }
    },
    length(){
      if(this.checked.length==this.tableList.length){
            this.isAll=true
      }else{
            this.isAll=false
      }
    },
    sure(){
      this.dialogVisible=false
      this.$emit('setTableHead',this.checked)
    }
  }
}
</script>

<style scoped lang="scss">
.el-icon-setting{
  font-size: 25px;
  border: 2px solid #409EFF;
  border-radius: 6px;
  color: #409EFF;
  line-height: 25px;
}
</style>