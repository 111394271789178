<template>
  <!-- 商品分析 -->
  <div class="storage" 
    v-loading.fullscreen.lock="loading" 
    element-loading-text="请求数据中"
    element-loading-background="rgba(255, 255, 255, 0.5)"
    >
     <div class="append">
       <div class="seach">
         <ShopsAndSites
           v-if="tress.length != 0"
           style="margin-right: 10px"
           :list="tress"
           @getShopId="getID"
         ></ShopsAndSites>
         <el-select 
           clearable
           style="margin-right: 20px" 
           v-model="pageInfoData.status"
           placeholder="商品状态" 
           @change="find"
         >
           <el-option
             v-for="item in goodsStatusList"
             :key="item.id"
             :label="item.value"
             :value="item.id">
           </el-option>
         </el-select>
         <el-select 
           clearable
           style="margin-right: 20px" 
           v-model="pageInfoData.distributeWay" 
           placeholder="配送方式" 
           @change="find"
         >
           <el-option
             v-for="item in distributeList"
             :key="item.id"
             :label="item.value"
             :value="item.id">
           </el-option>
         </el-select>
         <el-date-picker
          v-model="timeRange"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          style="margin-right: 20px"
         ></el-date-picker>
         <el-input style="width:360px;" v-model="keyword" placeholder="请输入ASIN" @keyup.enter.native = "find">
           <el-select 
             clearable
             @clear="clearAsin"
             slot="prepend"
             v-model="select_Asin" 
             placeholder="选择输入类型" 
             style="width: 130px"
             @change="find"
             >
              <el-option
                v-for="item in subASINList"
                :key="item.id"
                :label="item.value"
                :value="item.id">
              </el-option>
           </el-select>
         </el-input>
         <el-button type="primary" icon="el-icon-search" @click="find">查询</el-button>
         <tableDialog :tableList="tableData" @setTableHead="setTableHead" style="margin-right:20px;float:right"></tableDialog>
         <!-- 列表区域 -->
         <el-table
          border
          :data="tableList"
          :selectable="selectable"
          @sort-change="sortTable"
          style="width: 100%"
          :header-cell-style="{background:'#EFEFEF', textAlign:'center', height: '50px'}"
          >
            <el-table-column label="商品信息" align="center" width="270px" fixed>
              <template slot-scope="scope">
                <el-popover placement="right" width="520" trigger="hover" transition="fade-in-linear" close-delay="30">
                  <!-- 鼠标悬浮 -->
                  <div style="display:flex">
                    <div class="pic">
                      <span v-if="!scope.row.imageUrl">暂无图片</span>
                      <img v-else :src="scope.row.imageUrl" alt="" width="100%">
                    </div>
                    <div class="suspend" style="width:320px; font-size:14px">
                       <p class="infoText">{{scope.row.title}}</p>
                       <p style="margin-bottom:10px">
                         <span>
                           <img style="width:20px;margin:0 8px 0 0" :src="scope.row.marketPlaceImageUrl" alt=""/>
                           <p>子ASIN:{{scope.row.asin}}</p>
                         </span>
                        <span>父ASIN:{{scope.row.parentAsin}}</span></p>
                       <p><span>SKU:{{scope.row.commoditySku}}</span><span>品牌:{{scope.row.label}}</span></p>
                    </div>
                  </div>
                  <div class="goodsInfo" slot="reference">
                    <div class="noPic">
                      <span v-if="!scope.row.imageUrl">暂无图片</span>
                      <img v-else :src="scope.row.imageUrl" alt="" width="100%">
                    </div>
                    <div style="text-align:left">
                      <p><img style="width:20px;margin:0 6px 0 0" :src="scope.row.marketPlaceImageUrl" alt=""/>子ASIN:&nbsp;&nbsp;{{ scope.row.asin }}</p>
                      <p>SKU:&nbsp;&nbsp;{{ scope.row.commoditySku }}</p>
                    </div>
                  </div>
                </el-popover>
              </template>
            </el-table-column>
            <template v-for="item in tableHead">
              <el-table-column
                :key="item.prop"
                :prop="item.prop"
                :sortable="item.sortable?'custom':false"
                :label="item.name"
                header-align="center"
                align="center"
                :width="item.width"
              >
               <template slot="header">
                 <span v-if="item.name=='销售额'">
                   <span>{{item.name}}</span>
                    <el-tooltip effect="dark" placement="bottom">
                      <div slot="content"> 
                       <span>所指的是：<span style="color: #FF8C00">商品的销售额（欧洲市场shipped状态订单已扣VAT），而非订单金额</span>, 即不包含各项税费, </span><br/>
                       <span>运费、礼品费和扣除促销等相关费用的金额。</span><br/>
                       <span>计算逻辑：销售订单<span style="color: #FF8C00">（不包括：取消、预售未付款授权 状态的订单）</span>中的商品单价 * 销售数量的金额总和。</span><br/>
                       <span>某一笔订单的销售额（商品销售额），就是这笔订单在亚马逊后台交易详情中的商品价格。</span><br/>
                       <span>① 销售订单的归属日期，以下单时间进行统计;</span><br/>
                       <span>② 如果订单发生退款，此订单会正常统计销售额，对应的退款金额会以退款时间，统计到当日总支出的退款项中;</span><br/>
                       <span>③ 部分pending订单，亚马逊一段时间内，返回的销售数据可能为0,</span><br/>
                       <span>此时系统会按最近成交的情况进行预算<span style="color: #FF8C00">（预算数据项包括：销售额、亚马逊佣金、FBA费用）</span>;</span>
                      </div>
                      <span class="el-icon-question"></span>
                    </el-tooltip>
                 </span>
                 <span v-else-if="item.name=='广告销售额'">
                   <span>{{item.name}}</span>
                   <el-tooltip effect="dark" placement="top">
                     <div slot="content">
                       <span>通过广告带来的销售额。</span><br/>
                       <span>1、因近30天（尤其近3天）亚马逊接口返回的数据,</span><br/>
                       <span>可能与亚马逊控制台展现的数据存在略微不一致,</span><br/>
                       <span>因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致; </span><br/>
                       <span>2、以商品和站点不同维度查看数据, 因数据来自亚马逊不同的接口, </span><br/>
                       <span>不同接口的请求时间或者报告生成时间可能不一致, 因此可能导致两个维度下统计结果存在略微不一致;</span>
                     </div>
                     <span class="el-icon-question"></span>
                   </el-tooltip>
                 </span>
                 <span v-else-if="item.name=='ASoTS'">
                   <span>{{item.name}}</span>
                    <el-tooltip effect="dark" placement="top">
                      <div slot="content">
                        <span>通过广告所带来的销售额占总销售额的比例。</span><br/>
                        <span>计算规则: ASoTS = 广告销售额/ 销售额*100%;</span>
                      </div>
                      <span class="el-icon-question"></span>
                    </el-tooltip>
                 </span>
                 <span v-else-if="item.name=='销量'">
                  <span>{{item.name}}</span>
                  <el-tooltip effect="dark" placement="top">
                    <div slot="content">
                      <span>销售订单<span style="color: #FF8C00">(不包括: 取消, 预售, 预售未付款授权状态的订单)</span>中的商品数量的总和</span>
                    </div>
                    <span class="el-icon-question"></span>
                  </el-tooltip>
                 </span>
                 <span v-else-if="item.name=='订单量'">
                  <span>{{item.name}}</span>
                  <el-tooltip effect="dark" placement="top">
                    <div slot="content">
                      <span>销售订单<span style="color: #FF8C00">(不包括: 取消, 预售未付款授权状态的订单)</span>的数量。</span><br/>
                      <span>1: 销售订单的归属日期，以下单时间进行统计;</span><br/>
                      <span>2: 如果订单发生退款, 此订单会正常统计订单量;</span>
                    </div>
                    <span class="el-icon-question"></span>
                  </el-tooltip>
                 </span>
                 <span v-else-if="item.name=='广告订单量'">
                  <span>{{item.name}}</span>
                  <el-tooltip effect="dark" placement="top">
                    <div slot="content">
                      <span>通过广告带来的销售额。</span><br/>
                      <span>1、因近30天（尤其近3天）亚马逊接口返回的数据,</span><br/>
                      <span>可能与亚马逊控制台展现的数据存在略微不一致,</span><br/>
                      <span>因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致; </span><br/>
                      <span>2、以商品和站点不同维度查看数据, 因数据来自亚马逊不同的接口, </span><br/>
                      <span>不同接口的请求时间或者报告生成时间可能不一致, 因此可能导致两个维度下统计结果存在略微不一致;</span>
                    </div>
                    <span class="el-icon-question"></span>
                  </el-tooltip>
                 </span>
                 <span v-else-if="item.name=='广告订单占比'">
                   <span>{{item.name}}</span>
                   <el-tooltip effect="dark" placement="top">
                     <div slot="content">
                       <span>通过广告带来的订单量占总订单量的比例。</span><br/>
                       <span>计算规则: 广告订单占比 = 广告订单量/订单量*100%;</span>
                     </div>
                     <span class="el-icon-question"></span>
                   </el-tooltip>
                 </span>
                 <span v-else-if="item.name=='总支出'">
                  <span>{{item.name}}</span>
                  <el-tooltip effect="dark" placement="bottom">
                    <div slot="content">
                      <span>所有支出的总和，支出项包括：广告花费、FBA费用、亚马逊佣金、</span><br/>
                      <span>促销费用、退款金额 + 退款费用、亚马逊其它费用、系统设置商品成本 、系统设置其它成本。</span><br/>
                      <span><span style="color: #FF8C00">特别说明：</span>1、如果亚马逊平台代收了增值税，则亚马逊其它费用中会包含增值税，即总支出中包括增值税;</span><br/>
                      <span>2、coupon类促销订单, 系统的促销费用中会包括亚马逊收取的费用, 即总支出中包括coupon的使用费用,</span><br/>
                      <span>因此系统订单中展示的促销费用可能大于亚马逊交易一览中的促销金额; </span><br/>
                      <span>1、如果订单发生退款, 此订单会正常统计销售额, 对应的退款金额会以退款时间, 统计到当日总支出的退款项中;</span><br/>
                      <span>2、部分pending订单, 亚马逊短时间内, 返回的销售数据可能为0,</span><br/>
                      <span>此时系统会按最近成交的情况进行预算<span style="color: #FF8C00">（预算数据项包括：销售额、亚马逊佣金、FBA费用）</span>;</span><br/>
                      <span>3、亚马逊其它费用：亚马逊订单明细中, 除了 商品价格、FBA费用、佣金、促销费用 <span style="color: #FF8C00">之外的其它费用总和</span>, </span><br/>
                      <span>此值出现负数的原因是：a、有些促销活动是亚马逊平台搞的, 但是这部分费用会被一并统计到总支出的促销费用中, </span><br/>
                      <span> 因此需要进行对冲。b、增值税亚马逊未代收, 此时增值税需要加入到收入中, </span><br/>
                      <span> 对于费用来说是负数; c、FBM订单，运费需要加入到收入中, 对于费用来说是负数; </span><br/>
                      <span>4、系统设置其它成本：店铺实际运营过程中, 产生的一些特殊费用, 可以是收入, 也可以是支出, 用于准确计算利润, 可在“库存管理 - 其它成本”中进行上传;</span><br/>
                      <span>5、系统设置商品成本：包括商品的采购成本、物流成本（头程 / FBM）、其它费用, 可在“库存管理 - 商品成本”中进行设置或者上传;</span>
                    </div>
                    <span class="el-icon-question"></span>
                  </el-tooltip>
                 </span>
                <span v-else-if="item.name=='广告花费'">
                  <span>{{item.name}}</span>
                  <el-tooltip effect="dark" placement="bottom">
                    <div slot="content">
                      <span>广告被点击, 所产生的总费用, 相当于您的<span style="color: #FF8C00">广告总成本。</span></span>
                      <span>1、因近30天（尤其近3天）亚马逊接口返回的数据,</span><br/>
                      <span>可能与亚马逊控制台展现的数据存在略微不一致,</span><br/>
                      <span>因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致; </span><br/>
                      <span>2、以商品和站点不同维度查看数据, 因数据来自亚马逊不同的接口, </span><br/>
                      <span>不同接口的请求时间或者报告生成时间可能不一致, 因此可能导致两个维度下统计结果存在略微不一致;</span><br/>
                      <span>3、计算规则: 广告花费 = CPC竞价*被点击次数。</span>
                    </div>
                    <span class="el-icon-question"></span>
                  </el-tooltip>
                </span> 
                <span v-else-if="item.name=='ACoTS'">
                  <span>{{item.name}}</span>
                  <el-tooltip effect="dark" placement="top">
                    <div slot="content">
                      <span>广告花费占总销售的比例。</span><br/>
                      <span>计算规则: ACoTS = 广告花费/整体销售额*100%;</span>
                    </div>
                    <span class="el-icon-question"></span>
                  </el-tooltip>
                </span>
                <span v-else-if="item.name=='ACoS'">
                  <span>{{item.name}}</span>
                  <el-tooltip effect="dark" placement="bottom">
                    <div slot="content">
                      <span>广告销售成本率, 广告花费占广告销售额的比例。</span><br/>
                      <span>计算规则：ACoS = 广告花费 / 广告销售额 * 100%;</span><br/>
                      <span>例如, ACoS为90%, 100美元的销售额, 需要支付90%（90元）广告费。</span><br/>
                      <span>ACoS是衡量广告效果的重要指标, 一般来说越低越好, </span><br/>
                      <span>25%是业内普遍的衡量标准, </span><br/>
                      <span>但并不能用ACoS指标单一来衡量广告的效果。</span><br/>
                      <span>在不同的细分市场、商品周期、用户周期, 品牌定位, 都影响着ACoS的设置目标,</span><br/>
                      <span>例如，客户复购率高、忠诚度高、终身价值高，或是有品牌光环和生态的商品,</span><br/>
                      <span>通过广告一次获客, 客户可带来长期的购买回报, </span><br/>
                      <span>因此这类商品ACoS高, 反而有益于创造更多利润。</span>
                    </div>
                    <span class="el-icon-question"></span>
                  </el-tooltip>
                </span>
                <span v-else-if="item.name=='退货数量'">
                  <span>{{item.name}}</span>
                  <el-tooltip effect="dark" placement="top" content="退款订单中的商品数量的总和。">
                    <span class="el-icon-question"></span>
                  </el-tooltip>
                </span>
                <span v-else-if="item.name == '退货率'">
                  <span>{{item.name}}</span>
                  <el-tooltip effect="dark" placement="top" content="计算规则: 退货率 = 退货量/销售量*100%">
                    <span class="el-icon-question"></span>
                  </el-tooltip>
                </span>
                <span v-else-if="item.name == '毛利润'">
                  <span>{{item.name}}</span>
                  <el-tooltip effect="dark" placement="top" content="计算规则: 毛利润 = 销售额-总支出">
                    <span class="el-icon-question"></span>
                  </el-tooltip>
                </span>
                <span v-else-if="item.name == '毛利率'">
                  <span>{{item.name}}</span>
                  <el-tooltip effect="dark" placement="top" content="计算规则: 毛利率 = 毛利润/销售额*100%">
                    <span class="el-icon-question"></span>
                  </el-tooltip>
                  <template slot-scope="scope">
                    <el-tooltip placement="top">
                      <div slot="content">
                        <span v-if="scope.row.grossProfitTarget==2">达标</span>
                        <span v-if="scope.row.grossProfitTarget==1">不达标</span>
                        <span v-if="scope.row.grossProfitTarget==0">无指标状态</span>
                      </div>
                      <span class="dashed" v-if="scope.row.grossProfitTarget==0">{{scope.row.grossProfitRate}}</span>
                      <span class="dashed" v-if="scope.row.grossProfitTarget==1" style="color:red">{{scope.row.grossProfitRate}}</span>
                      <span class="dashed" v-if="scope.row.grossProfitTarget==2" style="color:#58A55C">{{scope.row.grossProfitRate}}</span>
                    </el-tooltip>
                  </template>
                </span>
                <span v-else-if="item.name == '广告曝光量'">
                  <span>{{item.name}}</span>
                  <el-tooltip effect="dark" placement="top">
                    <div slot="content">
                      <span>广告投放给买家的展示总数。</span><br/>
                      <span>没有曝光量就无法带来点击及购买转化, 因此整体预算内, 曝光量越高越好。</span>
                    </div>
                    <span class="el-icon-question"></span>
                  </el-tooltip> 
                </span>
                <span v-else-if="item.name == '广告点击量'">
                  <span>{{item.name}}</span>
                  <el-tooltip effect="dark" placement="top" content="广告被点击的总次数。">
                    <span class="el-icon-question"></span>
                  </el-tooltip>
                </span>
                <span v-else-if="item.name == '广告点击率'">
                  <span>{{item.name}}</span>
                  <el-tooltip effect="dark" placement="top">
                    <div slot="content">
                      <span>每一次曝光被点击的概率。</span><br/>
                      <span>计算规则: 广告点击率 = 点击量/曝光量*100%;</span><br/>
                      <span>亚马逊广告点击率一般在0.6%-2.5%左右。</span><br/>
                      <span>点击率高: 说明选词匹配度越高, 同时您的商品标题, 主图, 价格, 评论数量, 较好的吸引用户点击。</span><br/>
                      <span>点击率低: 建议对选词, 商品标题, 主题, 价格, 评论数量进行优化。</span>
                    </div>
                    <span class="el-icon-question"></span>
                  </el-tooltip>
                </span>
                <span v-else-if="item.name == 'CVR'">
                  <span>{{item.name}}</span>
                  <el-tooltip effect="dark" placement="bottom">
                    <div slot="content">
                      <span>每一次点击带来订单的概率。</span><br/>
                      <span>计算规则：CVR = 订单数 / 点击量 * 100%;</span><br/>
                      <span>例如：CVR为10%, 每100次点击, 能带来10笔广告订单。</span><br/>
                      <span>亚马逊广告, CVR一般为5-10%左右,</span><br/>
                      <span>CVR的重要影响因素：商品图片、卖点、评论内容、促销活动, </span><br/>
                      <span>如果CVR低, 建议您优化listing, 根据商品的受众人群, </span><br/>
                      <span>提炼卖点、设计精美的场景图、善用Q&A，Review等。</span>
                    </div>
                    <span class="el-icon-question"></span>
                  </el-tooltip> 
                </span>
                <span v-else-if="item.name == '访问次数'">
                  <span>{{item.name}}</span>
                  <el-tooltip effect="dark" placement="top">
                    <div slot="content">
                      <span>用户访问您的 Amazon.com 页面的次数。24 小时内的所有活动都将视为一次会话。</span><br/>
                      <span>数据来自于亚马逊后台业务报告, 可在 “经营分析” - “业务报告” 中进行上传。</span><br/>
                      <span><span style="color: #FF8C00">特别说明</span>：按SKU维度查看数据时, 如果1个ASIN下有多个SKU, 导入的ASIN业务报告数据, </span><br/>
                      <span>只会随机填充到一个SKU, 这会导致其它的SKU没有业务报告导入的相关数据。</span>
                    </div>
                    <span class="el-icon-question"></span>
                  </el-tooltip>
                </span>
                <span v-else-if="item.name=='访问转化率'">
                  <span>{{item.name}}</span>
                  <el-tooltip effect="dark" placement="top">
                    <div slot="content">
                      <span>计算规则：访问转化率 = 销量 / 访问次数 * 100%。</span><br/>
                      <span>访问次数, 数据来自于亚马逊后台业务报告, 可在 “经营分析” - “业务报告” 中进行上传。</span><br/>
                      <span><span style="color: #FF8C00">特别说明</span>：按SKU维度查看数据时, 如果1个ASIN下有多个SKU, 导入的ASIN业务报告数据, </span><br/> 
                      <span>只会随机填充到一个SKU, 这会导致其它的SKU没有业务报告导入的相关数据。</span>
                    </div>
                    <span class="el-icon-question"></span>
                  </el-tooltip>
                </span>
               </template>
              </el-table-column>
            </template>
         </el-table>
         <!-- 分页区域 -->
         <el-pagination 
           @size-change="handleSizeChange" 
           @current-change="handleCurrentChange"
           layout="total, sizes, prev, pager, next, jumper" 
           :page-sizes="[20, 30, 50, 100]" 
           :total="total"
           :page-size="pageInfoData.pageSize" 
           :current-page="pageInfoData.current" 
         >
         </el-pagination>
       </div>
     </div>
  </div>
</template>

<script>
import ShopsAndSites from "@/components/ShopsAndSites.vue";
import tableDialog from '@/components/tableDialog.vue'
var moment = require("moment");
import {
  productAnalysisPageInit,   //  商品分析初始化
  getListPage,
}from '@/api/businessAnalysis/productAnalysis.js'
export default {
  components: {
    tableDialog,
    ShopsAndSites,
  },
  data() {
    return {
      loading: false,
      pageInfoData: {
        status: "",
        distributeWay: "",   // 配送方式
        current: 1,
        pageSize: 20,
        sort:'',
        sortColumn:'',
      },
      total: 0,
      goodsStatusList: [],
      distributeList: [],
      subASINList: [],
      // 用户检索的时间范围
      timeRange: [ 
        moment(new Date().getTime() - 3600 * 1000 * 24 * 7).format(
            'YYYY-MM-DD '
        ),
        moment(new Date().getTime()).format('YYYY-MM-DD ') 
      ], 
      keyword: "",
      select_Asin: "",
      shopId: "",
      marketPlaceId: "",
      tress: [],
      tableList: [],
      tableHead: [],
      // 表头内容
      tableData: [
        { name: '销售额', prop: 'salesAmount', sortable: true },
        { name: '广告销售额', prop: 'adSalesAmount', sortable: true },
        { name: 'ASoTS', prop: 'asots', sortable: true },
        { name: '销量', prop: 'salesQuantity', sortable: true },
        { name: '订单量', prop: 'orderQuantity', sortable: true },
        { name: '广告订单量', prop: 'advertQuantity', sortable: true },
        { name: '广告订单占比', prop: 'advertProportion', sortable: true },
        { name: '总支出', prop: 'totalFee', sortable: true },
        { name: '广告花费', prop: 'advertFee', sortable: true },
        { name: 'ACoTS', prop: 'acots', sortable: true },
        { name: 'ACoS', prop: 'acos', sortable: true },
        { name: '退货数量', prop: 'returnQuantity', sortable:true },
        { name: '退货率', prop: 'returnRate', sortable: true },
        { name: '毛利润', prop: 'grossProfit', sortable: true },
        { name: '毛利率', prop: 'grossProfitRate', sortable:true },
        { name: '广告曝光量', prop: 'advertExposure', sortable:true },
        { name: '广告点击量', prop: 'advertClicks', sortable:true },
        { name: '广告点击率', prop: 'advertClickRate', sortable:true },
        { name: 'CVR', prop: 'cvr', sortable:true },
        { name: '访问次数', prop: 'visitsNumber', sortable:true },
        { name: '访问转化率', prop: 'visitsRate', sortable:true },
        { name: '优惠券金额', prop: 'promotionDiscount', sortable:true }
      ],
    }
  },
  created() {
    this.tableHead = this.tableData;
    this.getPageInit();
  },
  methods: {
    setTableHead(list) {
      this.tableHead = list;
    },
    getPageInit() {
      productAnalysisPageInit()
      .then(res=> {
        let result = res.data.data;
        if(res.data.code == 200) {
          this.tress = result.tress;
          this.goodsStatusList = result.productStatusList;
          this.distributeList = result.sendTypeList;
          this.subASINList = result.searchList;
        }
      })
    },
    // 查询事件
    Search() {
       this.loading = true;
       let Sku_Asin = "";
       if(this.select_Asin == "" || this.keyword == "") {
         Sku_Asin = null;
       }else if(this.select_Asin == "asin") {
         Sku_Asin = { asin: this.keyword }
       }else if(this.select_Asin == "parent_asin") {
         Sku_Asin = { parent_asin: this.keyword }
       }else if(this.select_Asin == "commodity_sku") {
         Sku_Asin = { commodity_sku: this.keyword }
       }
       let params = {
         current: this.pageInfoData.current,
         pageSize: this.pageInfoData.pageSize,
         shopId: this.shopId,
         marketPlaceId: this.marketPlaceId,
         status: this.pageInfoData.status,
         shipType: this.pageInfoData.distributeWay,
         beginTime: Array.isArray(this.timeRange)? this.timeRange[0] : "",
         endTime: Array.isArray(this.timeRange)? this.timeRange[1] : "",
         sort:this.pageInfoData.sort,
         sortColumn:this.pageInfoData.sortColumn
       }
       getListPage({...Sku_Asin, ...params})
       .then(res=> {
         this.tableList = [];
         this.total = 0;
         if(res.status == 200) {
           this.loading = false;
           this.tableList = res.data.values;
           this.total = res.data.pageInfo.total;
         }else if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }
       })
    },
    clearAsin() {
      this.keyword = ""; 
      this.select_Asin = ""; 
    },
    getID(shopID, marketPlaceId) {
      this.shopId = shopID;
      this.marketPlaceId = marketPlaceId;
      this.Search();
    },
    handleSizeChange(newSize) {
      this.pageInfoData.pageSize = newSize;
      this.Search();
    },
    handleCurrentChange(newCurrent) {
      this.pageInfoData.current = newCurrent;
      this.Search();
    },
    sortTable(column){
      console.log(column);
      if(column.order=='ascending'){
        this.pageInfoData.sort='asc'
      }else if(column.order=='descending'){
        this.pageInfoData.sort='desc'
      }else if(column.order==null){
        this.pageInfoData.sort=''
        this.pageInfoData.sortColumn=''
        this.Search()
        return
      }
      this.pageInfoData.sortColumn=column.prop
      this.Search()
    },
      find(){
        this.pageInfoData.current=1
        this.Search()
      }
  }
}
</script>

<style lang="scss" scoped>
.storage {
  .append {
    height: 50px;
    display: flex;
    justify-content: flex-start;
    .seach {
      width: 100%;
      .el-input {
        width: 230px;
        margin-right: 20px;
      }
      .el-table {
        margin-top: 30px;
      }
      .el-pagination {
        padding-top: 40px;
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }
}
.goodsInfo{
  display: flex;
  >div:nth-of-type(2){
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  p{
    font-size: 12px;
    color: #909399;
  }
}
.noPic{
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size:8px;
  color: #909399;
  margin-right: 15px;
  span{
    display: block;
    background: #EBEEF5;
    width: 100%;
    height: 100%;
  }
}
.pic{
  width:160px;
  height: 160px;
  font-size:8px;
  color: #909399;
  text-align: center;
  line-height: 160px;
  margin-right: 10px;
  span{
    display: block;
    background: #EBEEF5;
    width: 100%;
    height: 100%;
  }
}
.infoText{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 25px;
    margin: 15px 0 20px 0;
}
.suspend{
  p{
    span{
      display: inline-block;
      width: 140px;
      margin-right: 10px;
      font-size: 13px;
    }
  }
}
img{
  vertical-align: middle;
}
.dashed{
  display: inline-block;
  border-bottom: 1px dashed #999999;
  padding-bottom: 3px;
}
</style>